import { RedirectUrlComponent } from './redirect-url/redirect-url.component';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { NgZorroAntdModule, NzBreadCrumbModule, NzSelectModule } from 'ng-zorro-antd';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import {CommonModule} from '@angular/common';

import {
    OpenAppComponent,
    VerifyPhoneComponent,
    OtpComponent,
    AuthenticationComponent,
    AuthenticationLoginComponent,
    AuthenticationRegisterComponent,
    ForgotPasswordComponent,
    BuyCardComponent,
    StepFirstComponent,
    ResultComponent
} from './index';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {LoadingComponent} from './loading/loading.component';
import {AlertComponent} from './alert/alert.component';
import {IvyCarouselModule} from './carousel/carousel.module';
import { ImageComponent } from './image/image.component';
import {UploadFileComponent} from './upload-file/upload-file.component';
import {ModalComponent} from './modal/modal.component';
// @ts-ignore
// import {WebcamModule} from 'ngx-webcam';

@NgModule({
    declarations: [
        OpenAppComponent,
        VerifyPhoneComponent,
        OtpComponent,
        AuthenticationComponent,
        AuthenticationLoginComponent,
        AuthenticationRegisterComponent,
        ForgotPasswordComponent,
        BuyCardComponent,
        StepFirstComponent,
        ResultComponent,
        LoadingComponent,
        AlertComponent,
        RedirectUrlComponent,
        ImageComponent,
        UploadFileComponent,
        ModalComponent
    ],
    imports: [
        CommonModule,
        NgZorroAntdModule,
        ReactiveFormsModule,
        NzUploadModule,
        NzSelectModule,
        IonicModule,
        NzBreadCrumbModule,
        FormsModule,
        IvyCarouselModule,
        // WebcamModule
    ],
    exports: [
        OpenAppComponent,
        RedirectUrlComponent,
        VerifyPhoneComponent,
        OtpComponent,
        ImageComponent,
        AuthenticationComponent,
        AuthenticationLoginComponent,
        AuthenticationRegisterComponent,
        ForgotPasswordComponent,
        BuyCardComponent,
        StepFirstComponent,
        ResultComponent,
        LoadingComponent,
        AlertComponent,
        IvyCarouselModule,
        UploadFileComponent,
        ModalComponent
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class ShareModule {
}
