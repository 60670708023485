import {Component, ElementRef, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator} from '@angular/forms';

import {Observable, Subject} from 'rxjs';
import {UploadService} from '../services';
// @ts-ignore
import {WebcamImage, WebcamInitError} from 'ngx-webcam';
import * as moment from 'moment';

const IMAGE_REGEX = /(.*?)\.(jpg|png|tif|pjp|xbm|jxl|svgz|jpeg|ico|tiff|gif|svg|jfif|webp|bmp|pjpeg|avif)$/i;

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFileComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: UploadFileComponent,
      multi: true,
    },
  ]
})
export class UploadFileComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  constructor(private notificationService: NzNotificationService,
              private upload: UploadService) { }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }
  src: any;
  isShow = false;
  // @Output() onChange = new EventEmitter<any>();
  // @Output() onDelete = new EventEmitter<any>();

  // @Input() images: any = [];

  listSrc: any = [];
  dataImage: string[];
  captureImage: any;
  errors: WebcamInitError[] = [];

  onChange: (dataImage: any) => void;
  onTouched: () => void;

  // @Input() isFile: boolean = true;
  // @Input() isCapture: boolean = true;

  @Input() typeUpload = 'image';

  @ViewChild('videoUpload', {static: false}) $videoUpload: ElementRef;
  @ViewChild('fileUpload', {static: false}) $fileUpload: ElementRef;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId

  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  // toggle webcam on/off
  showWebcam = true;
  allowCameraSwitch = true;
  multipleWebcamsAvailable = false;
  deviceId: string;
  videoOptions: MediaTrackConstraints = {
    width: {ideal: 300},
    height: {ideal: 300}
  };

  onUploadVideo() {
    this.$videoUpload.nativeElement.value = null;
    this.$videoUpload.nativeElement.click();
  }

  onUploadFile() {
    this.$fileUpload.nativeElement.value = null;
    this.$fileUpload.nativeElement.click();
  }

  onCapture() {
    this.isShow = true;
    this.captureImage = null;
  }

  ngOnInit(): void {
  }

  // @ts-ignore
  // public set value(value: string[]) {
  //   console.log("value", value);
  //   this.onChange(value);
  // }

  onChangeFile(files) {
    // console.log("files1", files);
    if (files && files.length > 0) {
      // console.log("files", files);
      Array.from(files).forEach(file => {
        return this.upload.postImg(file).subscribe((res) => {
          // this.listSrc = [res.data.url];
          // console.log("value", this.listSrc);
          this.listSrc = this.listSrc.concat(res.data.url);
          this.writeValue(this.listSrc);
          this.onChange(this.listSrc);
          // this.onChange.emit(this.listSrc);
        });
        // if (+this.onGetSizeFile((file as any).size) > 5) {
        //   this.createNotification('error', 'THÔNG BÁO', 'Dung lượng file vượt quá 5MB');
        // } else {
        //   // if (IMAGE_REGEX.test((file as any).name)) {
        //     return this.upload.postImg(file).subscribe((res) => {
        //       // this.listSrc = [res.data.url];
        //       // console.log("value", this.listSrc);
        //       this.listSrc = this.listSrc.concat(res.data.url);
        //       this.writeValue(this.listSrc);
        //       this.onChange(this.listSrc);
        //       // this.onChange.emit(this.listSrc);
        //     });
        //   // } else {
        //   //   this.createNotification('error', 'THÔNG BÁO', 'Vui lòng chọn định dạng ảnh JPG, PNG, JPEG');
        //   // }
        // }
      });

      // this.banners = images;
    }
  }

  isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  onGetSizeFile = (size: string) => (+size / (1024 * 1024)).toFixed(2);

  createNotification(type, title, message) {
    this.notificationService.create(type, title, message);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (this.images) {
    //   this.listSrc = this.images;
    // }
  }

  onRemoveImage(index) {
    this.listSrc.splice(index, 1);
    // this.listFile.splice(index, 1);
    // this.onChange.emit(this.listSrc);
    this.writeValue(this.listSrc);
    this.onChange(this.listSrc);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // this.onChange = fn;
  }

  writeValue(obj: any): void {
    this.dataImage = obj ? obj : [];
    this.listSrc = obj ? obj : [];
  }

  validate(control: AbstractControl): ValidationErrors | null {
    console.log('control', control.value);
    console.log('control', control);
    return undefined;
  }

  public handleInitError(error: WebcamInitError): void {
    console.log('error', error);
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      console.warn('Camera access was not allowed by user!');
    }
    this.errors.push(error);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // tslint:disable-next-line:no-console
    console.info('received webcam image', webcamImage?.imageAsDataUrl);

    if (webcamImage?.imageAsDataUrl) {
      const imgFile = new File([webcamImage?.imageAsDataUrl.replace('data:image/jpeg;base64,' , '')], `${moment().format('DDMMYYYYHHmm')}.jpeg`);
      // console.info('imgFile', imgFile);
      this.upload.postImg(this.dataURLtoFile(webcamImage?.imageAsDataUrl, `${moment().format('DDMMYYYYHHmm')}.jpeg`)).subscribe(res => {
        this.captureImage = res.data.url;
      });
    }
    // this.captureImage = webcamImage;
  }

  dataURLtoFile(dataurl, filename) {
    // tslint:disable-next-line:one-variable-per-declaration prefer-const
    let arr = dataurl.split(','),
        // tslint:disable-next-line:prefer-const
        mime = arr[0].match(/:(.*?);/)[1],
        // tslint:disable-next-line:prefer-const
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        // tslint:disable-next-line:prefer-const
        u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    // this.deviceId = deviceId;
  }

  onConfirm(e) {
    if (e) {
      this.isShow = false;
      if (e === 'yes' && this.captureImage) {
        this.listSrc = this.listSrc.concat(this.captureImage);
        this.writeValue(this.listSrc);
        this.onChange(this.listSrc);
      }
    }
  }
}
