import {ShareService} from './layout/share/services/share.service';
import {AfterViewInit, Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {ModalController, Platform} from '@ionic/angular';
import {environment} from '../environments/environment';
import {isMobile} from './helpers/tracking-teko';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DeliService} from './deli.service';
import {NzNotificationService} from 'ng-zorro-antd';
import {LuckyWheelService} from './layout/luckywheel/services';
import {phongVuCheck} from './helpers';
import {userIcheck} from './helpers/userIcheck';
import {AlertComponent} from './layout/share/alert/alert.component';
import * as moment from 'moment';
import {RewardModalComponent} from './layout/luckywheel/components/reward-modal/reward-modal.component';
import {
    RewardSettingModalComponent
} from './layout/luckywheel/components/reward-setting-modal/reward-setting-modal.component';
import {GiftService} from './layout/gift-detail/services/gift-detail.service';
import {PdfModalComponent} from "./pdf-modal/pdf-modal.component";

const wheelItemColor = [['#ffffff', '#ffffff'], ['#fdedca', '#fdedca']];
// tslint:disable-next-line:prefer-const
let that;
// @ts-ignore
// tslint:disable-next-line:prefer-const
let Spin2 = require('../assets/js/win');

// // const Spin2 = require('../assets/js/win');
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

    constructor(
        public modalController: ModalController,
        private geolocation: Geolocation,
        private shareService: ShareService,
        public platform: Platform,
        private renderer2: Renderer2,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private deliService: DeliService,
        private notificationService: NzNotificationService,
        private luckyWheelService: LuckyWheelService,
        private giftService: GiftService,
    ) {
        that = this;
        this.shareService.deviceId.subscribe(deviceId => this.device_id = deviceId);
    }

    device_id: any;
    TOP_GIFT = {
        0: 'GIẢI ĐẶC BIỆT',
        1: 'GIẢI NHẤT',
        2: 'GIẢI NHÌ',
        3: 'GIẢI BA',
        4: 'GIẢI TƯ',
        5: 'GIẢI NĂM',
        6: 'GIẢI SÁU',
    };
    form: any;
    searchPhone: any;
    // tslint:disable-next-line:variable-name
    campaign_id: any;

    time_play = 0;
    dataThemeDefault: any;
    colorDefault: any;
    dataDesignById: any;
    background_customer: any;
    srcHeaderBackground: any;
    codeTheme: any;
    srcMainBackground: any;
    currentCampaign: any;
    campaignName: any;
    box_games: any;
    indexGiftActive = 0;

    running: boolean;
    giftGame: any;
    gifts: any;
    campaign: any;
    campaignId: any;
    winner_id: any;
    configRewardForm = [];
    disableAction = false;
    gift_result: any;
    data_game: any;
    metadata: any;
    metadataAvaiable: any;
    isCreatedForm = false;
    city;
    ward;
    district;
    cities: any;
    districts: any;
    wards: any;
    clickSubmit = false;
    defaultField = ['phone', 'name', 'email', 'city_id', 'district_id', 'ward_id', 'address', 'rule', 'main_image', 'lottery_code_ver2'];

    campaign_code: any;
    winners: any = [];
    dataCampaign: any;
    isShowVQMM = false;

    isLoading = true;

    isDomain = false;

    async ngOnInit() {
        // this.campaign_id = environment.campaignIdChild;
        this.addReCaptcha();
        this.initForm();
        await this.loadInfoCampaign();
        this.loadWinnerHistory();
    }

    addReCaptcha() {
        const script = this.renderer2.createElement('script');
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.keyCaptcha}`;
        script.async = true;
        script.defer = true;
        // this.renderer.appendChild(this.document.head, script);
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    async loadInfoCampaign() {
        this.campaign_id = localStorage.getItem('campaignIdChild');
        // this.form = this.formBuilder.group({
        //   name: [null, [Validators.required]],
        //   city_id: [null, [Validators.required]],
        //   phone: [null, [Validators.required]],
        //   lottery_code_ver2: [null, [Validators.required]]
        // });
        this.listCities();
        this.getTimePlay();
        await this.loadThemes();
        await this.loadData();
    }

    initForm() {
        this.giftService.getForm(environment.campaignId).subscribe(res => {
            console.log('res', res);
            if (res?.statusCode === 200) {
                console.log('vao day', res?.data);
                this.metadata = res?.data?.information_fields?.information;
                this.metadataAvaiable = this.metadata?.filter(item => !item.is_hidden);
                this.metadataAvaiable.push({
                    id: 'lottery_code_ver2',
                    input_type: 'text',
                    is_hidden: false,
                    is_meta: false,
                    is_required: true,
                    placeholder: 'Nhập mã dự thưởng',
                    title: 'Mã dự thưởng'
                });
                if (this.metadataAvaiable?.length) {
                    this.createForm();
                }
            }
        });
    }

    createForm() {
        const group = {};

        if (!this.isCreatedForm) {
            this.isCreatedForm = true;
            const existingMetadata = JSON.parse(sessionStorage.getItem('metadata'));
            this.metadataAvaiable.forEach((config, index) => {
                let defaultValue = '';
                const validators = [];
                if (config.id === 'city_id') {
                    this.listCities();

                }

                // if (config.id === 'name') {
                //   validators.push(Validators.maxLength(255));
                // }

                if (config.id === 'phone') {
                    // validators.push(this.validatePhone);
                    // validators.push(Validators.pattern(new RegExp(/^((\\+91-?)|0)?[0-9]{10}$/)));
                    validators.push(Validators.pattern('(84[3|5|7|8|9]|0[3|5|7|8|9])+([0-9]{8})\\b'));
                }
                if (config.is_required) {
                    validators.push(Validators.required);
                }

                if (config.id === 'email') {
                    validators.push(this.validateEmail);
                    // validators.push(Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'));
                }

                if (config.id !== 'phone' && config.id !== 'email') {
                    validators.push(Validators.maxLength(255));
                }

                if (config.id !== 'city_id' && config.id !== 'district_id' && config.id !== 'ward_id') {
                    defaultValue = null;
                }
                group[config.id] = new FormControl(defaultValue, validators);
                //
                // if (index === this.metadataAvaiable.length - 1) {
                //   console.log("vao day casi")
                //   validators.push(Validators.required);
                //   group['lottery_code_ver2'] = new FormControl(null, validators);
                // }
                // }x
            });
            // if (this.campaign?.id == 2430 || this.campaign?.id == 2877 || this.campaign?.owner?.id == this.LOrealId) {
            //   group['rule'] = new FormControl(false, [Validators.requiredTrue]);
            // }
            this.form = new FormGroup(group);

            console.log('form', this.form);
            // lottery_code_ver2
            // tslint:disable-next-line:variable-name
            let district_id;
            for (const property in this.form.value) {
                if (existingMetadata && existingMetadata.length) {
                    const existingField = existingMetadata.find(element => (element.key === property && element.value));
                    if (existingField && existingField.value !== 'null') {
                        this.form.controls[property].setValue(existingField.value);

                        if (property === 'district_id') {
                            district_id = existingField.value;
                        }
                    }
                }
            }
            if (!district_id) {
                this.form.patchValue({
                    district_id: '',
                    ward_id: ''
                });
            }
        }
    }

    changeValueSelect(event, key) {
        if (key === 'city_id') {

            this.city = parseInt(event?.target?.value);
            // console.log("event", event)
            if (this.city) {
                this.listDistricts(this.city);
                this.form.patchValue({
                    district_id: '',
                    ward_id: ''
                });
                this.wards = [];
            } else {
                this.form.patchValue({
                    district_id: '',
                    ward_id: ''
                });
                this.districts = [];
                this.wards = [];
            }
        } else if (key === 'district_id') {
            this.district = parseInt(event?.target?.value);
            if (this.district) {
                this.listWards(this.district);
                this.form.patchValue({
                    ward_id: ''
                });
            } else {
                this.form.patchValue({
                    ward_id: ''
                });
                this.wards = [];
            }
        } else {
            this.ward = parseInt(event);
        }
    }

    listWards(districId) {
        this.giftService.getWards(districId).subscribe(res => {
            this.wards = res.rows;
        });
    }

    listDistricts(cityId) {
        this.giftService.getDistricts(cityId).subscribe(res => {
            this.districts = res.rows;
        });
    }

    validateEmail(email) {
        if (email?.value?.trim()) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(email.value.trim().toLowerCase())) {
                return {pattern: true};
            }
        } else {
            return null;
        }

    }

    listCities() {
        this.giftService.getCities().subscribe(res => {
            this.cities = res?.rows || null;
        });
    }

    onReceiveSpin() {
        if (this.form.valid) {
            // lottery_code_ver2

            const filtered = Object.keys({...this.form.value})
                .filter(key => !this.defaultField.includes(key))
                .reduce((obj, key, index) => {
                    obj[key] = this.form.value[key];
                    return obj;
                }, {});
            const metadata = this.metadataAvaiable.map(it => {
                for (const property in filtered) {
                    if (property === it.id) {
                        it.value = filtered[property];
                    }
                }
                return {
                    id: it.id,
                    key: it.title,
                    value: it.value ? it.value : ''
                };
            }).filter(item => this.defaultField.indexOf(item.id) === -1);

            const recursive = (f: FormGroup | FormArray) => {
                for (const i in f) {
                    if (typeof f[i] === 'string') {
                        f[i] = f[i].trim();
                        if (f[i].trim().length === 0) {
                            f[i] = null;
                        }
                    }
                }
            };

            const body = {
                ...this.form.value,
                sms_code: this.form.get('lottery_code_ver2').value,
                // campaign_id: environment.campaignId,
                device_id: this.device_id,
                metadata,
                // metadata: [{key: 'target', value: this.form.get('lottery_code_ver2').value.toUpperCase()},
                //   {key: 'target_type', value: 'qr_mar'},
                //   {key: 'source', value: 'qr_mkt'},
                //   {key: 'requestId', value: '123123'}]
            };
            recursive(body);
            // this.removeEmptyQuery(body);
            delete body.lottery_code_ver2;
            this.deliService.joinCampaign(body).subscribe(async (res: any) => {
                if (res?.statusCode === 200) {
                    const dataCampaign = res?.data;
                    // this.form.reset
                    dataCampaign.result = res?.data?.result?.length ? res?.data?.result.filter(item => item?.reward?.has_reward) : [];
                    // this.form.reset();
                    if (dataCampaign?.result?.length) {
                        this.notificationService.success('THÔNG BÁO', `Chúc mừng bạn đã có thêm ${dataCampaign.result[0]?.reward?.play} lượt chơi.`);
                        localStorage.setItem('phone', this.form.get('phone').value);
                        localStorage.setItem('campaignIdChild', dataCampaign?.result[0].id);
                        localStorage.setItem('lottery_code_ver2', this.form.get('lottery_code_ver2').value);
                        // localStorage.setItem('campaign_id', dataCampaign?.result[0]?.id);
                        await this.loadInfoCampaign();
                        this.getTimePlay();
                    } else {
                        this.notificationService.error('THÔNG BÁO', 'CHÚC BẠN MAY MẮN LẦN SAU');
                    }
                } else {
                    this.notificationService.error('THÔNG BÁO', res?.message || 'Mã không hợp lệ');
                }
            });
        } else {
            this.notificationService.error('THÔNG BÁO', 'VUI LÒNG ĐIỀN ĐẦY ĐỦ THÔNG TIN');
        }
    }

    removeEmptyQuery(query: any) {
        Object.keys(query).forEach(key => {
            if (query[key] === undefined || query[key] === null || query[key] === '') {
                delete query[key];
            }
        });
        return query;
    }

    getTimePlay() {
        if (this.campaign_id) {
            const phone = localStorage.getItem('phone');
            // tslint:disable-next-line:variable-name
            const campaign_id = localStorage.getItem('campaign_id');
            if (phone) {
                const params = {
                    campaign_id: this.campaign_id,
                    phone,
                };
                this.deliService.getTurnCount(params).subscribe(res$ => {
                    if (res$ && res$.data) {
                        this.time_play = res$.data.play;
                    }
                });
            }
        }
    }

    async loadData() {
        // tslint:disable-next-line:variable-name
        // const campaign_id = localStorage.getItem('campaign_id');
        if (this.campaign_id) {
            this.isShowVQMM = false;
            console.log('this.campaign_id', this.campaign_id);
            await this.luckyWheelService.getDetailByID(this.campaign_id, {form: true, isValidActionTime: false}).subscribe(res => {
                // console.log("xxx", res?.data)
                this.dataCampaign = res?.data;
                this.campaign_code = res?.data?.code;
                this.loadThemesDefault(res?.data?.id);
                this.loadWinnerHistory();
                this.setDataDefault(res);
                this.isShowVQMM = true;
            });
        }
        this.isLoading = true;
        // setTimeout(() => {
        //     this.isLoading = true;
        //     this.checkCurrentDomain();
        // }, 500);
    }


    ngAfterViewInit(): void {
        this.checkCurrentDomain();
    }

    loadWinnerHistory() {
        // if (this.campaign_code) {
        this.deliService.winnerHistoryMain(environment.campaignCode, {
            search: this.searchPhone || '',
            offset: 0,
            limit: 16,
            business_owner_id: environment.business_owner_id
            // campaign_id: environment.campaignId
        }).subscribe((res: any) => {
            // console.log("taun nguyen hung", res);
            this.winners = res?.data?.rows;
            // console.log("taun nguyen hung", this.winners);
        });
        // }
    }

    loadThemesDefault(id) {
        return this.luckyWheelService.getTheme(this.campaign_id).subscribe(res => {
            this.dataThemeDefault = res.data;
            // this.colorDefault = res.data?.wheel_config?.item_color?.length ? res.data.wheel_config.item_color : wheelItemColor;
            this.colorDefault = wheelItemColor;
            if (this.dataDesignById?.luky_wheel?.background_game_rotation) {
                this.colorDefault = wheelItemColor;
            }
        });
    }

    async loadThemes() {
        if (this.campaign_id) {
            this.luckyWheelService.getThemeGame(this.campaign_id).subscribe(res => {
                if (res.statusCode === 200) {
                    this.dataDesignById = res.data;
                    this.background_customer = res.data.background_customer?.original;
                    this.srcHeaderBackground = res.data.luky_wheel.banner?.original;
                }
            });
        }

    }

    async getGiftSelected(body) {
        return this.luckyWheelService.playGame(body).toPromise();
    }

    async startGame() {
        if (this.running) {
            return;
        }

        // if (this.dataCampaign.status_time === 'PENDING') {
        //   await this.showAlert('Chương trình chưa bắt đầu');
        //
        //   return;
        // }
        //
        // if (this.dataCampaign.status_time === 'COMPLETED') {
        //   await this.showAlert('Chương trình đã kết thúc');
        //   return;
        // }
        if (this.time_play <= 0) {
            // alert('Bạn đã hết lượt quay!');
            await this.showAlert('Bạn đã hết lượt quay');
            return;
        }
        this.disableAction = true;
        this.running = true;
        const data$ = this.data_game;
        const phone = localStorage.getItem('phone');
        // tslint:disable-next-line:variable-name
        const lottery_code_ver2 = localStorage.getItem('lottery_code_ver2');
        const body = {
            campaign_id: this.campaignId,
            phone,
        } as any;
        if (!body.phone) {
            delete body.phone;
        }

        if (lottery_code_ver2) {
            body.lottery_code_ver2 = lottery_code_ver2;
        }

        let myWheel = new Spin2.Spin2WinWheel();
        const mySpinBtn = document.querySelector('.spinBtn123');
        // data$.segmentValuesArray[0].probability = 100;
        // data$.timeUp = 60;
        // myWheel.init({
        //     data: data$,
        //     onResult: this.endGame,
        //     spinTrigger: mySpinBtn
        // });
        // myWheel.reset();
        // myWheel.start();

        let gift;
        // tslint:disable-next-line:one-variable-per-declaration
        let errorMessage, errorStatus;

        try {
            gift = await this.getGiftSelected(body);
            errorMessage = gift?.data.message;
            errorStatus = gift?.status;
        } catch (exception) {
            console.log(exception);
            errorMessage = exception?.error?.message;
            errorStatus = exception?.error?.status;
        } finally {
            this.disableAction = false;
        }

        if (gift && gift.statusCode === 200 && gift.status === 'SUCCESS') {
            this.giftGame = gift;
            const winner = gift.data.winner;
            this.winner_id = winner.id;
            const status = gift.status;
            if (status === 'SUCCESS') {
                const data = gift.data.gift;
                localStorage.setItem('currentGift', JSON.stringify(gift.data));
                data$.segmentValuesArray[0].probability = 0;
                data$.timeUp = 1;
                await data$.segmentValuesArray.map(i => {
                    if (i.id === data.id) {
                        i.probability = 100;
                        i.userData.message = gift;
                        i.userData.success = true;
                        return;
                    }
                    i.probability = 0;
                });
                // tslint:disable-next-line:no-shadowed-variable
                const mySpinBtn = document.querySelector('.spinBtn123');
                myWheel = new Spin2.Spin2WinWheel();
                myWheel.init({
                    data: data$,
                    onResult: this.endGame,
                    spinTrigger: mySpinBtn
                });
                myWheel.reset();
                myWheel.start();
                this.configRewardForm = gift.data.config_reward_form;
                this.gift_result = gift;
                this.time_play = gift.data.play;
                return;
            }
            return;
        } else {
            // alert(gift?.data.message);
            // await this.showAlert(errorMessage, errorStatus);
            console.log("this.box_games", this.box_games);
            const gifts = this.box_games?.length ? this.box_games.filter(item => item.gift.type === 'CONGRATULATION') : [];
            if (gifts?.length) {
                const randomGift = gifts[Math.floor(Math.random() * gifts.length)];
                const data = randomGift.gift;
                localStorage.setItem('currentGift', JSON.stringify(randomGift));
                data$.segmentValuesArray[0].probability = 0;
                data$.timeUp = 1;
                await data$.segmentValuesArray.map(i => {
                    if (i.id === data.id) {
                        i.probability = 100;
                        i.userData.message = data;
                        i.userData.success = true;
                        return;
                    }
                    i.probability = 0;
                });
                // tslint:disable-next-line:no-shadowed-variable
                const mySpinBtn = document.querySelector('.spinBtn123');
                myWheel = new Spin2.Spin2WinWheel();
                myWheel.init({
                    data: data$,
                    onResult: this.endGame,
                    spinTrigger: mySpinBtn
                });
                myWheel.reset();
                myWheel.start();
                this.gift_result = {
                    data: {randomGift, gifts: [randomGift?.gift]},
                    statusCode: 200
                };
                console.log("gift_result", this.gift_result)
            } else {
                await this.showAlert(errorMessage, errorStatus);
            }
            myWheel.reset();
            // this.running = false;
            this.giftGame = null;
            // data$.segmentValuesArray[0].userData.success = false;
            // data$.segmentValuesArray[0].userData.failedMessage = gift?.data.message;
        }

        this.getTimePlay();
        // this.running = false;
    }

    async setDataDefault(response: any) {
        const res = response.data;
        this.codeTheme = res?.theme?.code;
        this.srcMainBackground = res?.background_rotation?.small;
        this.background_customer = res?.background_rotation?.small ? res.background_rotation?.small : this.background_customer;
        this.srcHeaderBackground = res?.header_image_rotation?.original ? res.header_image_rotation?.original : this.srcHeaderBackground;
        this.currentCampaign = res;
        if (this.currentCampaign?.id === 1761) {
            // @ts-ignore
            this.dataDesignById.wheel_config?.item_color = [['#52a6d9', '#0a87cc'], ['#FFFFFF', '#FFFFFF']];
        }
        this.campaignName = res?.name;
        if (res?.id) {
            this.gifts = res?.box?.gifts;

            this.campaign = res;
            this.getGitfs(res.id, res?.theme?.code, this.dataDesignById?.luky_wheel?.background_game_rotation);
            this.campaignId = res.id;
            this.getTimePlay();
        }
    }

    getGitfs(id?: number, codeTheme?: any, segmentStrokeColor?: any) {
        const data$ = {
            colorArray: [`url(${window.location.href}#gradItem1)`, `url(${window.location.href}#gradItem2)`],
            segmentValuesArray: [],
            svgWidth: 1024,
            svgHeight: 670,
            wheelStrokeColor: `url(${window.location.href}#wheelInnerStroke)`,
            wheelStrokeWidth: 17,
            wheelCoverStrokeColor: `url(${window.location.href}#wheelCoverStroke)`,
            wheelCoverStrokeWidth: 36,

            // duojng kinh banh xe
            wheelSize: 800,
            wheelTextOffsetY: 50,
            wheelTextColor: 'black',
            wheelTextSize: '2.3em',
            wheelImageOffsetY: 50,
            wheelImageSize: 170,
            // đường kinh của đường tròn trung tâm
            centerCircleSize: 50,
            centerCircleStrokeColor: '#F1DC15',
            centerCircleStrokeWidth: 12,
            // mau cua hình tròn trung tâm
            centerCircleFillColor: 'orange',
            segmentStrokeColor: segmentStrokeColor ? '' : codeTheme === 'minigame3' ? 'rgba(226, 223, 223, 0.5)' : '#E2E2E2',
            segmentStrokeWidth: 0,
            centerX: 512,
            centerY: 384,
            hasShadows: false,
            // so lan quay: -1 la vo han
            numSpins: -1,
            spinDestinationArray: [],
            minSpinDuration: 6,
            gameOverText: '',
            errorTurnText: '',
            invalidSpinText: '',
            introText: '',
            hasSound: true,
            gameId: '9a0232ec06bc431114e2a7f3aea03bbe2164f1aa',
            clickToSpin: true,
            spinDirection: 'ccw',
            timeUp: 1
        };
        this.luckyWheelService.getGame(id, {box: true}).subscribe(
            async res => {
                if (res.statusCode === 200) {
                    const gifts = res.data.box.box_games;
                    this.box_games = gifts.sort((a, b) => a.total_gift - b.total_gift);
                    console.log('box_games', this.box_games);
                    this.indexGiftActive = 0;
                    const arr = [];
                    await gifts.map(item => {
                        const object = {
                            probability: 0,
                            type: 'image',
                            value: item?.gift?.image?.original,
                            win: false,
                            resultText: '',
                            id: item.gift.id,
                            userData: {
                                giftId: item.gift.id,
                                message: item
                            }
                        };
                        arr.push(object);
                    });
                    data$.segmentValuesArray = arr;
                    if (arr.length === 6) {
                        // data$.wheelImageSize = 170;
                        data$.wheelImageSize = 200;
                    }
                    if (arr.length === 8) {
                        // data$.wheelImageSize = 150;
                        data$.wheelImageSize = 160;
                    }
                    if (arr.length === 10) {
                        // data$.wheelImageSize = 120;
                        data$.wheelImageSize = 110;
                    }
                    this.data_game = data$;
                    // console.log("this.box", this.box_games);
                    this.createGame(data$);
                }
            }
        );
    }

    createGame(response) {
        // console.log("tuan nguyen hung", response)
        const mySpinBtn = document.querySelector('.vqmm-loyalty');
        const myWheel = new Spin2.Spin2WinWheel();
        myWheel.init({
            data: response,
            spinTrigger: mySpinBtn
        });
    }

    async openPDF() {
        // const modal = await this.modalController.create({
        //     component: PdfModalComponent,
        //     cssClass: 'reward',
        //     componentProps: {
        //     },
        //     backdropDismiss: false,
        // });
        // return await modal.present();
        window.open(`https://assets.icheck.vn/application/2024/app/11/26/121c29e82d2a308692efb320a8faf3f7.pdf`, '_blank')
    }

    async showAlert(message, status?) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            cssClass: 'reward',
            componentProps: {
                message,
                status
            },
            backdropDismiss: false,
        });
        return await modal.present();
    }

    async endGame(res?: any) {
        that.running = false;
        if (res.userData.success) {
            window.parent.postMessage('winner', '*');
            // await this.modalGift();
        } else {
            await this.showAlert(res.userData.failedMessage);
            // alert(res.userData.failedMessage);
        }
    }

    getDate(date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    @HostListener('window:message', ['$event'])
    async onClick(event) {
        if (event && event.data === 'winner') {
            await this.modalGift();
        }
        console.log('event', event);
    }

    async modalGift() {
        this.running = false;
        // if (this.configRewardForm == null || !this.configRewardForm.length) {
        if (this.gift_result) {
            await this.showModalReward(this.gift_result);
            this.running = false;
            setTimeout(() => {
                this.disableAction = false;
            }, 500);
        }
        // }
        // else {
        //   if (this.gift_result) {
        //     await this.showModalSettingReward(this.gift_result);
        //     this.running = false;
        //     setTimeout(() => {
        //       this.disableAction = false;
        //     }, 500);
        //   }
        // }
    }

    async showModalReward(gift) {
        console.log('this.gift========>', gift);

        const modal = await this.modalController.create({
            component: RewardModalComponent,
            cssClass: 'reward',
            componentProps: {
                listGiftInput: gift,
                campaignName: this.campaignName,
                target: 'undefined',
                // campaignId: environment.campaignIdChild,
                campaignId: localStorage.getItem('campaignIdChild'),
                phone: localStorage.getItem('phone'),
                parent_campaign_id: environment.campaignId,
                source: null,
                winner_id: this.winner_id,
                code: '',
                campaign: this.campaign,
                type: null
            },
            backdropDismiss: false,
        });
        modal.onDidDismiss().then(val => {
            console.log('val', val);
            if (val?.data === 'winner') {
                this.loadWinnerHistory();
            }
        });
        return await modal.present();
    }

    async showModalSettingReward(gift) {
        const modal = await this.modalController.create({
            component: RewardSettingModalComponent,
            cssClass: 'reward',
            componentProps: {
                listGiftInput: gift,
                campaignName: this.campaignName,
                target: 'undefined',
                campaignId: this.currentCampaign?.id,
                phone: localStorage.getItem('phone'),
                winner_id: this.winner_id,
                code: null,
                campaign: this.campaign,
                configRewardForm: this.configRewardForm,
                parent_campaign_id: environment.campaignId,
                source: null,
                type: null
            },
            backdropDismiss: false,
        });
        return await modal.present();
    }

    checkCurrentDomain() {
        const currentDomain = window.location.hostname;
        const currentPath = this.router.url;
        const url = location.href;
        const count = (url.match(/\//g) || []).length;

        if (count === 3) {
            console.log('URL chỉ chứa domain:', currentDomain);
            // return true;
            this.isDomain = true;
        } else {
            console.log('URL có thêm đường dẫn khác.');
            // return false;
            this.isDomain = false;
        }

    }
}
