import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss'],
})
export class PdfModalComponent implements OnInit {

  constructor(private modalController: ModalController) {}

  async dismiss() {
    await this.modalController.dismiss();
  }

  ngOnInit() {}

}
